:root {
    --footer: #B4BC5A;
    --navbar: #E1EB71;
    --colorbar: #E1EB71;
    --maintext: black;
    --carouseltext: black;
    --mainbg: #FDFDFD;
}

.row-header{
    margin:0px auto;
    padding:0px auto;
}

.row-content {
    margin:0px auto;
    padding: 50px 0px 50px 0px;
    border-bottom: 1px ridge;
    min-height: 400px;
}

.footer{
    margin:0px auto;
    padding: 20px 0px 20px 0px;
    background-color: var(--footer);
    color: var(--maintext);
}

.jumbotron {
    padding: 120px 30px 70px 30px;
    margin:0px auto;
    background-image: url("https://raw.githubusercontent.com/Morganhtrotter/epstein-ed/dependabot/npm_and_yarn/bootstrap-4.3.1/public/assets/images/jumbo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    color: var(--maintext);
    font-family: 'Raleway', sans-serif;
    border-radius: 0px;
}

.navbar-dark {
    background-color: var(--navbar);
    font-family: 'Roboto', sans-serif;
}

.page-enter {
    opacity: 0.01;
    transform: translateX(-100%);
}

.page-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: all 300ms ease-in;
}

.body {
    font-family: 'Montserrat', serif;
}

p, ul {
    font-family: 'Montserrat', serif;
}

h3 {
    margin: 20px 0;
    font-family: 'Oswald', sans-serif;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--carouseltext);
}

h2, h4 {
    font-family: 'Oswald', sans-serif;
}

.cardTitle {
    font-family: 'Roboto Condensed', sans-serif;
    color: white;
    font-size: 5vh;
    opacity: 0.5;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  [class*="cardTitle"] {
    font-size: 20pt;
  }
}

.bg {
    background-color: floralwhite;
    height: 100vh;
    background-image: url("http://shopsafemarin-env.eba-vjj6mcnm.us-east-2.elasticbeanstalk.com/assets/images/bananas.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    display: block;
    margin-left: -6px;
}

.logom {
    display: none;
}

@media all and (max-width: 499px) {
    .logo {display: none;}
    .logom {display: block}
}

.colorBar {
    background-color: var(--colorbar);
    height: 15vh;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addressFooter {
    color: white;
}

.carousel {
    height: 90vh;
    min-height: 500px;
    overflow: hidden;
    position: relative;
    text-align: center;
}

.caption {
    justify-content: center;
    position: absolute;
    top: 200px;
}


.captionText {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    padding: 16px;
    border-radius: 5px;
    background-color: rgba(220, 220, 220, 0.8);
    color: var(--carouseltext);
    height: auto;
    box-shadow: 5px 5px 10px black;
}

.captionText > p {
    font-weight: bolder;
    font-size: 17px;
}

.captionText > h3 {
    color: var(--carouseltext);
    font-size: 35px;
}

.carouselImg {
    background-image: url('https://raw.githubusercontent.com/Morganhtrotter/epstein-ed/dependabot/npm_and_yarn/bootstrap-4.3.1/public/assets/images/Second.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding-top: -300px;
    width: 100%;
}

@media only screen and (max-device-width: 724px) {
  .carouselImg {
    width: unset;
    background-size: cover;
  }
}

.homeAbout {
    padding: 50px 0;
}

.forms {
    margin-bottom: 50px;
}

.headshot {
    width: 100%;
}

.caps {
    font-weight: bold;
}

.testimonial {
    padding-top: 50px;
    padding-bottom: 50px;
    color: black;
    background-color: var(--colorbar);
    margin: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    line-height: 2rem;
}

.testimonialText, .headerOutline {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px black;
}

.navbar-dark .navbar-nav .nav-link  {
    color: black !important;
}

.navbar-dark .navbar-nav .nav-link:hover  {
    color: blue !important;
}

body {
    color: var(--maintext);
    background-color: var(--mainbg)
}

.navbar {
    position: fixed;
    width: 100%;
    z-index: 999;
    box-shadow: 0px 5px 10px #606060;
}